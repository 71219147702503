const SearchSuggestionIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.67435 12.8519V5.3389L4.68816 4.71741L5.30964 4.7036H12.8227V5.97419H6.82882L15.6953 14.8406L14.8114 15.7245L5.94494 6.85807V12.8519H4.67435Z"
        fill="#251000"
        fill-opacity="0.56"
      />
    </svg>
  )
}

export default SearchSuggestionIcon
